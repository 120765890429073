<template>
  <!-- Desc: budget and bid component for campaign budget change and adgroup bid change tab -->
  <div class="card min-height-card u-spacing-p-l">
    <loader
      v-show="campaignInfo.status === 'loading'"
      class="fill--parent"
      :loading="campaignInfo.status === 'loading'"
      :color="'#3684bb'"
    />
    <div v-if="!setRoasValue && !adjustBudget">
      <!-- <div class="u-display-flex u-flex-align-items-center">
        <rb-icon
          class="u-color-grey-lighter"
          icon="alerts"
        />
        <div
          class="u-color-grey-lighter u-font-size-3 u-spacing-ml-s u-text-uppercase u-font-weight-600"
        >
          Recommendations To Adjust Daily Budget
        </div>
      </div> -->

      <!-- <div class="rec-card u-spacing-mt-l">
        <h2 class=" rec-label-class">
          Allow Campaign best practices
        </h2>
        <span
          class="u-font-size-7 u-spacing-mb-m description"
        >Campaign best practices is to set roas goal. Set the roas goal.</span>
        <span
          class="u-spacing-pb-s u-font-size-7 u-font-weight-600"
        >Recommendations</span>
        <div class="u-width-100 iconText count-class">
          <span class="u-display-flex u-text-overflow-ellipsis">
            <rb-icon
              class="rb-icon--small"
              icon="logo"
            />
            <span
              class="textClass count-icon-text-class"
            >{{ roasRecommendedValue }}%</span>
          </span>
        </div>
        <rb-button
          text="Set ROAS Goal"
          :disabled="true"
          size="s"
          :click-fn="setRoasFunction"
        />
      </div> -->

      <div class="u-spacing-pv-m">
        <rb-button
          :text="'Adjust Bid & Budget'"
          type="filled"
          :click-fn="onAdjustBudget"
          class="u-min-width-100px"
        />
      </div>
      <div class="u-spacing-pt-m u-font-size-5 u-font-weight-600">
        Budget Type:
      </div>
      <div class="u-spacing-pb-s u-spacing-pt-s u-font-size-5">
        {{ initialData.budgetTypeView }}
      </div>
      <div class="u-display-flex u-spacing-pt-s">
        <div v-if="initialData.budgetType === '0'">
          <div class="u-spacing-pb-s u-font-size-5 u-font-weight-600">
            Daily Budget:
          </div>
          <div class="u-position-relative">
            <rb-input
              v-model="initialData.dailyBudget"
              class="u-display-flex u-width-100px u-flex-align-items-center"
              :disabled="true"
            />
            <div
              class="u-font-size-5 u-color-grey-lighter value-left u-position-absolute"
            >
              {{ currency }}
            </div>
          </div>
        </div>
        <div :class="initialData.budgetType === '0' ? 'u-spacing-ml-xl' : ''">
          <div class="u-spacing-pb-s u-font-size-5 u-font-weight-600">
            Lifetime Budget:
          </div>
          <div class="u-position-relative">
            <rb-input
              v-model="initialData.lifetimeBudget"
              class="u-display-flex u-width-100px u-flex-align-items-center"
              :disabled="true"
            />
            <div
              class="u-font-size-5 u-color-grey-lighter value-left u-position-absolute"
            >
              {{ currency }}
            </div>
          </div>
        </div>
      </div>
      <div class="u-spacing-pt-m u-font-size-5 u-font-weight-600">
        Bid Type:
      </div>
      <div class="u-spacing-pb-s u-spacing-pt-s u-font-size-5">
        {{ initialData.bid.type }}
      </div>
      <div class="u-width-55 u-font-size-6 u-color-grey-lighter">
        {{ initialData.bid.desc }}
      </div>
      <div v-if="optimizedBiddingGoals.includes(initialData.bidType)">
        <div class="u-spacing-pt-m u-font-size-5 u-font-weight-600">
          Min. ROAS Goal:
        </div>
        <div class="u-position-relative">
          <rb-input
            v-model="initialData.roasValue"
            class="u-display-flex u-width-100px u-flex-align-items-center"
            :disabled="true"
          />
          <div
            class="u-font-size-5 u-color-grey-lighter value-left u-position-absolute"
          >
            {{ currency }}
          </div>
        </div>
      </div>
    </div>
    <!-- adjust budget -->
    <div class="u-display-flex">
      <div
        v-if="setRoasValue || adjustBudget"
        class="u-display-flex u-flex-direction-column u-height-100 u-width-55"
      >
        <div class="">
          <div class="u-spacing-pb-s u-font-size-5 u-font-weight-600">
            Adjust budget type:
          </div>
          <div class="u-display-flex u-spacing-mb-m">
            <div class="u-width-25">
              <rb-radio
                v-model="budgetTypeRadio"
                native-value="0"
              >
                Daily Budget
              </rb-radio>
            </div>
            <div class="u-width-33"></div>
          </div>
          <div class="u-spacing-pb-s u-font-size-5 u-font-weight-600">
            Adjust budget:
          </div>
          <div
            :key="budgetTypeRadio === '0' ? initialBudgetSet : lifetimeValue"
          >
            <div class="u-display-flex">
              <radioSelectionWithInput
                class="u-spacing-pt-s"
                :radio-config="
                  budgetTypeRadio === '0'
                    ? budgetConfig.daily
                    : budgetConfig.lifetime
                "
                :input-title="
                  budgetTypeRadio === '0'
                    ? budgetConfig.dailyBudgetTitle
                    : budgetConfig.lifetimeBudgetTitle
                "
                :daily-budget-checkbox="true"
                :default-radio-selected="lifetimeBudgetRadioDefault"
                :default-input-value="
                  budgetTypeRadio === '0' ? initialBudgetSet : lifetimeValue
                "
                :error-message="errorMessage"
                @onUpdate="onUpdatingValues"
              />
              <div
                v-if="budgetTypeRadio === '0'"
                class="u-spacing-mt-l"
              >
                <div class="u-font-size-5 u-font-weight-600">
                  Lifetime budget
                  <span
                    v-if="isOptimizedBiddingEnabled"
                    class="u-color-grey-lighter u-spacing-mr-s"
                    >(Optional)</span
                  >
                </div>
                <div>
                  <div class="u-spacing-mt-s u-spacing-mb-s">
                    <rb-radio
                      v-model="lifetimeRadio"
                      native-value="0"
                    >
                      <div class="u-display-flex u-spacing-mt-s">
                        <span
                          class="appender currency-symbol u-display-flex u-flex-align-items-center u-font-size-6 u-spacing-pl-s u-flex-0"
                          :class="
                            lifetimeRadio === '1' ? 'u-color-grey-xx-light' : ''
                          "
                        >
                          {{ $currency }}
                        </span>
                        <input
                          v-model="lifetimeValue"
                          type="number"
                          :disabled="lifetimeRadio === '1'"
                          min="0"
                          class="rb-input currency-input u-display-flex u-flex-align-items-center"
                          step="0.01"
                          :class="lifetimeRadio === '1' ? 'disabled-color' : ''"
                        />
                      </div>
                    </rb-radio>
                  </div>
                  <div>
                    <rb-radio
                      v-model="lifetimeRadio"
                      native-value="1"
                    >
                      Remove lifetime budget
                    </rb-radio>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--bidding strategy-->

          <div
            class="u-color-grey-light u-display-flex u-flex-direction-column"
          >
            <div
              class="u-spacing-mt-l u-spacing-mb-m u-font-size-5 u-font-weight-600"
            >
              Bidding strategy:
            </div>
            <div
              v-if="
                budgetTypeRadio === '0' && initialData.bidType === 'acquire_ntb'
              "
              class="u-spacing-mb-m"
            >
              <rb-radio
                v-model="bidType"
                :disabled="disableOptimized"
                native-value="acquire_ntb"
              >
                Optimize, Boost New to Brand:
              </rb-radio>
              <div
                class="u-color-grey-lighter u-font-size-6 u-line-height-1-1 u-spacing-ml-l u-spacing-mt-s u-width-400px"
              >
                Maximize sales at lowest cost per click possible with continual
                bid optimization. Only available with daily budget settings.
              </div>
              <div
                v-if="bidType === 'acquire_ntb'"
                class="u-color-grey-light u-spacing-ml-l u-spacing-mt-m"
              >
                <rb-checkbox
                  v-model="minRoasChecked"
                  :disabled="disableMinRoas"
                >
                  <div class="u-font-size-6">Set min ROAS Goal:</div>
                </rb-checkbox>

                <div class="u-display-flex u-spacing-mt-s">
                  <span
                    class="appender currency-symbol u-display-flex u-flex-align-items-center u-font-size-6 u-spacing-pl-s u-flex-0"
                    :class="
                      disableMinRoas || !minRoasChecked
                        ? 'u-color-grey-xx-light'
                        : ''
                    "
                  >
                    {{ $currency }}
                  </span>
                  <input
                    v-model="minRoasValue"
                    type="number"
                    :disabled="disableMinRoas || !minRoasChecked"
                    min="0"
                    class="rb-input currency-input u-display-flex u-flex-align-items-center"
                    step="0.01"
                    :class="
                      disableMinRoas || !minRoasChecked ? 'disabled-color' : ''
                    "
                  />
                </div>

                <div
                  v-if="minRoasValueError.length > 0"
                  class="u-color-red-base u-display-flex u-flex-align-items-center u-spacing-mt-s"
                >
                  <rb-icon
                    class="u-flex-0 rb-icon--small"
                    icon="error-fill"
                  />
                  <div class="u-flex-0 u-spacing-ml-xs u-font-size-7">
                    {{ minRoasValueError }}
                  </div>
                </div>

                <!-- <div
                v-if="showDailyBudget && bidType === '0'"
                class="u-spacing-mt-m"
              >
                <div class="u-display-flex u-flex-align-items-center">
                  <div>
                    <rb-icon
                      class="
                        u-color-grey-lighter
                        rb-icon--medium
                        u-cursor-pointer u-spacing-pb-l
                      "
                      icon="info-circle-fill"
                    />
                  </div>
                  <div class="u-font-size-5 u-spacing-ml-xs">
                    Requires Daily Budget
                  </div>
                </div>
                <div class="u-font-size-6 u-line-height-1-1">
                  Switching to optimized bidding means that the default and
                  override CPC bids will <br>
                  no longer be applied. This change will take effect within the
                  next business day.
                </div>

                <div class="u-color-grey-light u-spacing-mt-m">
                  <div class="u-font-size-6">
                    Daily budget:
                  </div>

                  <div class="u-display-flex u-spacing-mt-s">
                    <span
                      class="
                        appender
                        currency-symbol
                        u-display-flex
                        u-flex-align-items-center
                        u-font-size-6
                        u-spacing-pl-s
                        u-flex-0
                      "
                    >
                      {{ $currency }}
                    </span>
                    <input
                      v-model="dailyBudgetValue"
                      type="number"
                      min="20"
                      class="
                        rb-input
                        currency-input
                        u-display-flex u-flex-align-items-center
                      "
                      step="0.1"
                    >
                  </div>
                </div>
                <div class="u-color-grey-light u-spacing-mt-m">
                  <rb-checkbox v-model="lifetimeBudgetChecked">
                    <div class="u-font-size-6">
                      Lifetime budget:
                    </div>
                  </rb-checkbox>

                  <div class="u-display-flex u-spacing-mt-s">
                    <span
                      class="
                        appender
                        currency-symbol
                        u-display-flex
                        u-flex-align-items-center
                        u-font-size-6
                        u-spacing-pl-s
                        u-flex-0
                      "
                      :class="
                        !lifetimeBudgetChecked ? 'u-color-grey-xx-light' : ''
                      "
                    >
                      {{ $currency }}
                    </span>
                    <input
                      v-model="lifetimeBudgetValue"
                      type="number"
                      :disabled="!lifetimeBudgetChecked"
                      min="0"
                      class="
                        rb-input
                        currency-input
                        u-display-flex u-flex-align-items-center
                      "
                      step="0.01"
                    >
                  </div>
                </div>
              </div> -->
              </div>
            </div>

            <div
              v-if="showOptimizedBiddingGoals"
              class="u-spacing-mb-m"
            >
              <rb-radio
                v-model="bidType"
                :disabled="disableOptimized"
                native-value="max_sales"
              >
                Optimize, Max Sales:
              </rb-radio>
              <div
                class="u-color-grey-lighter u-font-size-6 u-line-height-1-1 u-spacing-ml-l u-spacing-mt-s u-width-400px"
              >
                Maximize sales at lowest cost per click possible with continual
                bid optimization. Only available with daily budget settings.
              </div>
              <div
                v-if="bidType === 'max_sales'"
                class="u-color-grey-light u-spacing-ml-l u-spacing-mt-m"
              >
                <rb-checkbox
                  v-model="minRoasChecked"
                  :disabled="disableMinRoas"
                >
                  <div class="u-font-size-6">Set min ROAS Goal:</div>
                </rb-checkbox>

                <div class="u-display-flex u-spacing-mt-s">
                  <span
                    class="appender currency-symbol u-display-flex u-flex-align-items-center u-font-size-6 u-spacing-pl-s u-flex-0"
                    :class="
                      disableMinRoas || !minRoasChecked
                        ? 'u-color-grey-xx-light'
                        : ''
                    "
                  >
                    {{ $currency }}
                  </span>
                  <input
                    v-model="minRoasValue"
                    type="number"
                    :disabled="disableMinRoas || !minRoasChecked"
                    min="0"
                    class="rb-input currency-input u-display-flex u-flex-align-items-center"
                    step="0.01"
                    :class="
                      disableMinRoas || !minRoasChecked ? 'disabled-color' : ''
                    "
                  />
                </div>

                <div
                  v-if="minRoasValueError.length > 0"
                  class="u-color-red-base u-display-flex u-flex-align-items-center u-spacing-mt-s"
                >
                  <rb-icon
                    class="u-flex-0 rb-icon--small"
                    icon="error-fill"
                  />
                  <div class="u-flex-0 u-spacing-ml-xs u-font-size-7">
                    {{ minRoasValueError }}
                  </div>
                </div>
              </div>
            </div>

            <div :class="budgetTypeRadio === '0' ? 'u-width-60' : 'u-width-66'">
              <rb-radio
                v-if="budgetTypeRadio === '0' && showOptimizedBiddingGoals"
                v-model="bidType"
                :disabled="disableManual"
                native-value="manual"
              >
                Manual:
              </rb-radio>
              <div
                v-if="budgetTypeRadio === '1'"
                class="u-font-size-5"
              >
                Manual:
              </div>
              <div
                v-if="budgetTypeRadio === '0'"
                class="u-font-size-6 u-color-grey-lighter u-line-height-1-1 u-spacing-ml-l u-spacing-mt-s u-width-480px"
              >
                Set your own cpc bids and optional override bids for specific
                keywords.
              </div>
              <div
                v-if="
                  (bidType === 'manual' || budgetTypeRadio === '1') &&
                  adgroupTableData.rows.length > 0
                "
                class="u-spacing-mt-m"
              >
                <div
                  v-if="budgetTypeRadio === '0'"
                  class="u-display-flex u-flex-align-items-center"
                >
                  <div>
                    <rb-icon
                      class="u-color-grey-lighter rb-icon--medium u-cursor-pointer u-spacing-pb-l"
                      icon="info-circle-fill"
                    />
                  </div>
                  <div class="u-font-size-5 u-spacing-ml-xs">
                    Requires Ad Group Bid
                  </div>
                </div>
                <div
                  class="u-font-size-6 u-line-height-1-1 u-width-560px u-color-grey-lighter"
                >
                  Switching to manual bidding means that you’ll have to define a
                  maximum default CPC bid for all<br />
                  ad groups within this campaign. This change will take effect
                  within the next business day.
                </div>
              </div>
              <div
                v-if="
                  bidType === 'manual' &&
                  adgroupTableData.rows.length > 0 &&
                  !disableManual
                "
                class="table-right paginationFooter u-border-color-grey-xxx-light u-border-left u-border-width-s u-spacing-mb-m u-spacing-mt-l"
              >
                <rb-insights-table
                  class="card"
                  :grid-options="gridOptions"
                  :config="tableConfigsLeft"
                  :auto-configure="adgroupTableData"
                  style="width: 100%"
                  :row-height="66"
                  :enable-client-side-sorting="false"
                  :enable-server-side-sorting="false"
                  :pagination="true"
                  :client-side-pagination="true"
                  :pagination-total-key="adgroupTableData.rows.length"
                  :get-current-instance="getCurrentSourceInstance"
                  :pagination-per-page-limit="10"
                  :download-this-table="false"
                  :show-pagination-options="false"
                  :row-no-hover-state="true"
                />
              </div>
              <div
                v-if="adgroupBidErrorMessage"
                class="u-display-flex u-color-red-base u-spacing-mt-s u-flex-align-items-center"
              >
                <div><rb-icon icon="cross-fill-circle" /></div>
                <div class="u-spacing-ml-xs u-font-size-7">
                  {{ adgroupBidErrorMessage }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="u-spacing-pt-xl">
          <footerButtons
            :disable-left-btn="applyButtonDisabled"
            :left-button-type="applyButtonDisabled ? 'filled' : 'hollow'"
            :button-min-width="'u-min-width-100px'"
            @onClickBtnRight="onCancel"
            @onClickBtnLeft="onSave"
          />
        </div>
      </div>
      <div
        v-if="(setRoasValue || adjustBudget) && budgetTypeRadio === '1'"
        class="u-display-flex u-flex-align-items-center u-border-left u-spacing-pl-xxxl u-line-height-1-3 u-spacing-pv-m u-font-size-5 u-color-grey-lighter u-border-color-grey-xxx-light u-border-width-s"
      >
        <div>
          <rb-icon
            class="u-color-grey-lighter rb-icon--medium u-cursor-pointer u-spacing-pb-l"
            icon="info-circle-fill"
          />
        </div>
        <div
          class="u-display-flex u-flex-align-items-center u-font-size-6 u-spacing-ml-xs"
        >
          By selecting maximize impressions now you can also add keywords <br />
          inside ad groups.
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import { cloneDeep } from 'lodash';
import loader from '@/components/basic/loader';
import config from './bidbudget';
import { commons } from '@/components/ams/campaigns/commons.js';
import transformer from '@/utils/services/data-transformer';
import HttpService from '@/utils/services/http-service';
import footerButtons from '@/pages/entity_details/common_components/footerButtons.vue';
import { deepReplaceObjectValues } from '@/pages/entity_details/common_components/helper.js';
import radioSelectionWithInput from '@/components/pages/instacart/campaigns/campaigns/adjustBudget/radioSelectionWithInput.vue';

export default {
  components: {
    footerButtons,
    loader,
    radioSelectionWithInput
  },
  props: {
    tabMetadata: {
      default: () => {},
      type: Object
    },
    entityConfig: {
      default: () => {},
      type: Object
    },
    entityId: {
      default: null,
      type: String
    },
    parentEntityId: {
      default: null,
      type: String
    }
  },
  data() {
    return {
      adgroupBidErrorMessage: '',
      errorMessage: '',
      initialBudgetSet: null,
      budgetTypeRadio: '',
      reqMetadataMap: null,
      adGroupDataForViewPayload: [],
      radioValue: null,
      isAdgroup: false,
      lifetimeBudgetRadioDefault: 'set_new_budget',
      config: null,
      payloadDataForBudget: {},
      payloadDataForManual: [],
      rbTableSourceInstance: null,
      optimizedBiddingGoals: ['max_sales', 'acquire_ntb'],
      tableConfigsLeft: {
        body: {
          APIConfig: {
            page: 1
          }
        }
      },
      gridOptions: {
        domLayout: 'normal',
        context: {
          componentParent: {}
        }
      },
      getState: null,
      requestPayload: null,
      updateMetadata: null,
      adjustBudget: false,
      budget: null,
      roasRecommendedValue: 7,
      setRoasValue: false,
      tempBudget: null,
      currency: Vue.prototype.$currency,
      tabConfig: null,
      bidType: null,
      minRoasValueError: '',
      minRoasChecked: false,
      disableManual: false,
      adGroupsData: [],
      oldAdGroupData: [],
      showDailyBudget: false,
      minRoasValue: null,
      disableMinRoas: false,
      disableOptimized: false,
      lifetimeRadio: '0',
      lifetimeValue: null
    };
  },
  computed: {
    isOptimizedBiddingEnabled() {
      return this.optimizedBiddingGoals.includes(this.bidType);
    },
    isInitialptimizedBiddingEnabled() {
      return this.optimizedBiddingGoals.includes(this.initialData.bidType);
    },
    showOptimizedBiddingGoals() {
      return (
        this.budgetTypeRadio === '0' &&
        this.initialData.bidType !== 'acquire_ntb'
      );
    },
    hideFooter() {
      if (
        this.budgetTypeRadio === '1' &&
        (this.transformTempBudget() <= 0 || isNaN(this.transformTempBudget()))
      ) {
        this.errorMessage = 'Lifetime Budget is mandatory';
        return true;
      } else if (
        this.budgetTypeRadio === '0' &&
        this.lifetimeValue &&
        this.transformTempBudget() > this.lifetimeValue &&
        this.lifetimeRadio === '0'
      ) {
        this.errorMessage = 'Daily Budget should be less than lifetime budget';
        return true;
      } else if (
        this.isOptimizedBiddingEnabled &&
        this.budgetTypeRadio === '0' &&
        this.transformTempBudget() < 20
      ) {
        this.errorMessage = 'Min Daily Budget for optimized should be 20$';
        return true;
      } else if (
        this.isOptimizedBiddingEnabled &&
        this.budgetTypeRadio === '0' &&
        isNaN(this.transformTempBudget())
      ) {
        this.errorMessage = 'Daily Budget is mandatory';
        return true;
      } else if (
        this.bidType === 'manual' &&
        this.budgetTypeRadio === '0' &&
        (this.transformTempBudget() <= 0 ||
          isNaN(this.transformTempBudget())) &&
        (+this.lifetimeValue === 0 || isNaN(this.lifetimeValue))
      ) {
        this.errorMessage =
          'At least one of the Daily Budget or LifeTime Budget is required';
        return true;
      } else if (
        (this.initialData.budgetType === '1' || this.budgetTypeRadio === '1') &&
        (this.adGroupDataForViewPayload?.length > 0 ||
          this.initialData.lifetimeBudget !== +this.tempBudget)
      ) {
        this.errorMessage = '';
        return false;
      } else if (
        (this.initialData.budgetType === '0' || this.budgetTypeRadio === '0') &&
        (this.initialData.bidType !== this.bidType ||
          ((this.isOptimizedBiddingEnabled ||
            this.initialData.isInitialptimizedBiddingEnabled) &&
            +this.minRoasValue !== this.initialData.roasValue) ||
          ((this.bidType === 'manual' ||
            this.initialData.bidType === 'manual') &&
            this.adGroupDataForViewPayload?.length > 0) ||
          this.lifetimeValue !== this.initialData.lifetimeBudget ||
          this.initialData.dailyBudget !== +this.tempBudget)
      ) {
        this.errorMessage = '';
        return false;
      } else {
        this.errorMessage = '';
        return true;
      }
    },
    updatedGroupData() {
      let result = false;
      for (let i = 0; i < this.adGroupsData?.length; i++) {
        if (
          +this.adGroupsData[i]?.default_bid !==
          this.oldAdGroupData[i]?.default_bid
        ) {
          result = true;
        }
      }
      return result;
    },
    campaignInfo() {
      return this.$store.getters[this.getState];
    },
    initialData() {
      this.budgetTypeRadio = '0';
      this.lifetimeValue = this.campaignInfo?.data?.budget;
      this.initialBudgetSet = this.campaignInfo?.data?.target_daily_budget;
      this.bidType = this.campaignInfo?.data?.optimized_bidding_goal;
      this.minRoasValue =
        this.campaignInfo?.data?.optimized_bidding_parameters?.roas_constraint;
      this.onUpdatingValues({
        radioValue: 'set_new_budget',
        inputValue:
          this.campaignInfo?.data?.budget_type === 'daily_budget'
            ? `${this.campaignInfo?.data?.target_daily_budget}`
            : `${this.campaignInfo?.data?.budget}`,
        inputType: 'input',
        dropdownLabel: null,
        dropdownValue: null
      });

      return {
        bid: {
          type: this.campaignInfo?.data?.optimized_bidding_enabled
            ? 'Optimized'
            : 'Manual',
          desc: this.campaignInfo?.data?.optimized_bidding_enabled
            ? 'Maximize sales at lowest cost per click possible with continual bid optimization. Only available with daily budget settings.'
            : 'Set your own cpc bids and optional override bids for specific keywords.'
        },
        dailyBudget: this.campaignInfo?.data?.target_daily_budget,
        lifetimeBudget: this.campaignInfo?.data?.budget,
        roasValue:
          this.campaignInfo?.data?.optimized_bidding_parameters
            ?.roas_constraint,
        budgetType: '0',
        budgetTypeView: 'Daily',
        campaignId: this.campaignInfo?.data?.campaignId,
        bidType: this.campaignInfo?.data?.optimized_bidding_goal ?? 'manual'
      };
    },
    applyButtonDisabled() {
      // if (this.tempBudget && this.budget !== this.tempBudget) {
      //   return true
      // }
      if (this.hideFooter || !this.bidType) {
        return true;
      }
      if (
        this.isOptimizedBiddingEnabled &&
        this.minRoasChecked &&
        !this.minRoasValue
      ) {
        this.minRoasValueError = 'Please add min ROAS Goal';
        return true;
      } else if (
        this.bidType === 'acquire_ntb' &&
        this.minRoasChecked &&
        this.minRoasValue < 0.1
      ) {
        this.minRoasValueError =
          'ROAS should be more than 0.1 for Boost New to Brand';
        return true;
      } else if (
        this.isOptimizedBiddingEnabled &&
        this.minRoasChecked &&
        this.minRoasValue > 20
      ) {
        this.minRoasValueError = "ROAS Goal can't exceed 20";
        return true;
      } else {
        this.minRoasValueError = '';
        return false;
      }
    },
    adgroupTableData() {
      return {
        rows: this.adGroupsData || [],
        columns: this.adGroupMetadata
      };
    },
    budgetConfig() {
      const type = 'budget';
      return {
        daily: [
          {
            title: `Set new ${type}`,
            type: 'input',
            value: 'set_new_budget'
          },
          {
            title: `Increase ${type}`,
            value: 'increase_budget',
            type: 'dropdown',
            dropdown: [
              {
                label: 'Change by percentage',
                value: 'percentage'
              },
              {
                label: 'Change absolute value',
                value: 'absolute_value'
              }
            ]
          },
          {
            title: `Decrease ${type}`,
            value: 'decrease_budget',
            type: 'dropdown',
            dropdown: [
              {
                label: 'Change by percentage',
                value: 'percentage'
              },
              {
                label: 'Change absolute value',
                value: 'absolute_value'
              }
            ]
          }
        ],
        dailyBudgetTitle: 'Daily Budget:',
        lifetimeBudgetTitle: 'Lifetime Budget:',
        lifetime: [
          {
            title: `Set new lifetime ${type}`,
            type: 'input',
            value: 'set_new_budget'
          },
          {
            title: `Increase lifetime ${type}`,
            value: 'increase_budget',
            type: 'dropdown',
            dropdown: [
              {
                label: 'Change by percentage',
                value: 'percentage'
              },
              {
                label: 'Change absolute value',
                value: 'absolute_value'
              }
            ]
          },
          {
            title: `Decrease lifetime ${type}`,
            value: 'decrease_budget',
            type: 'dropdown',
            dropdown: [
              {
                label: 'Change by percentage',
                value: 'percentage'
              },
              {
                label: 'Change absolute value',
                value: 'absolute_value'
              }
            ]
          }
        ]
      };
    }
  },
  watch: {
    minRoasChecked(val) {
      this.minRoasValue = val ? this.initialData.roasValue : null;
    },
    lifetimeRadio(val) {
      if (val === '1') {
        this.lifetimeValue = '0';
      }
    },
    bidType(val) {
      if (val === 'manual') {
        this.getAdGroupsData();
      }
    },
    budgetTypeRadio(val) {
      this.adGroupDataForViewPayload = [];
      if (val === '1') {
        this.bidType = 'manual';
        this.getAdGroupsData();
      }
      if (val === '0') {
        this.bidType = this.initialData?.bidType;
      }
      this.onUpdatingValues({
        radioValue: 'set_new_budget',
        inputValue:
          val === '0'
            ? `${this.campaignInfo?.data?.target_daily_budget}`
            : `${this.campaignInfo?.data?.budget}`,
        inputType: 'input',
        dropdownLabel: null,
        dropdownValue: null
      });
    },
    campaignInfo: {
      handler(newValue, oldValue) {
        if (newValue?.status === 'loaded') {
        }
      },
      immediate: true
    }
  },
  created() {
    const tabConfig = this.entityConfig.tabs[this?.tabMetadata?.component];
    this.tabConfig = tabConfig;
    this.getState = tabConfig?.apiConfigs?.getter;
    this.updateMetadata = tabConfig?.apiConfigs?.updateAction;
    this.requestPayload = tabConfig?.requestPayload;
    this.requestPayloadForManual = tabConfig?.requestPayloadForManual;
    this.reqMetadataMap = tabConfig?.reqMetadataMap;
    this.isAdgroup = tabConfig?.isAdgroup || false;
    this.config = config;
    this.adGroupMetadata = this.config.adGroupColumnMetadata(this);
    this.bidType = this.initialData.bidType ?? 'manual';
    this.minRoasChecked = !!this.minRoasValue;
  },
  methods: {
    getAdGroupDataForViewPayload() {
      this.adGroupDataForViewPayload = [];
      if (this.isOptimizedBiddingEnabled) return;
      this.adgroupBidErrorMessage = '';
      for (let i = 0; i < this.adGroupsData?.length; i++) {
        // if (
        //   +this.adGroupsData[i]?.default_bid !==
        //   this.oldAdGroupData[i]?.default_bid
        // ) {
        if (
          +this.adGroupsData[i]?.default_bid < 0.3 ||
          +this.adGroupsData[i]?.default_bid > 85
        ) {
          this.adgroupBidErrorMessage =
            'Bid should be between $0.3 and $85, both inclusive.';
        } else {
          this.adGroupDataForViewPayload.push({
            adgroupId: this.adGroupsData[i].ad_group_id,
            adgroupName: this.adGroupsData[i].ad_group_name,
            oldBidValue: this.oldAdGroupData[i]?.default_bid,
            newBidValue: +this.adGroupsData[i]?.default_bid
          });
        }
        // }
      }
    },
    addSuggestedBid(context) {
      if (!context.params.data.suggested_bid) return;
      let id = context.params.data.ad_group_id;
      this.adGroupsData = this.adGroupsData.map((item) => {
        if (item.ad_group_id === id) {
          item.default_bid = context.params.data.suggested_bid;
        }
        return item;
      });
      this.rbTableSourceInstance.redrawTableRows();
      this.getAdGroupDataForViewPayload();
    },

    addAllSuggestedBids() {
      this.adGroupsData = this.adGroupsData.map((item) => {
        if (item.suggested_bid) {
          item.default_bid = item.suggested_bid;
        }
        return item;
      });
      this.rbTableSourceInstance.redrawTableRows();
      this.getAdGroupDataForViewPayload();
    },

    getCurrentSourceInstance(context) {
      this.rbTableSourceInstance = context;
    },
    getAdGroupsData() {
      this.config.reqBody.where.dimensionNameValueList[0].dimensionValue =
        this.initialData.campaignId;
      HttpService.post('EXECUTE_CUBE_API', this.config.reqBody)
        .then((response) => {
          this.adGroupsData = [
            ...transformer.mergeResultDimension(response.data.response.data)
          ];
          this.oldAdGroupData = [
            ...transformer.mergeResultDimension(response.data.response.data)
          ];
        })
        .catch((error) => console.log(error));
    },

    // setRoasFunction () {
    //   if (this.bidType === '1') {
    //     this.getAdGroupsData();
    //   }
    //   this.setRoasValue = true;
    //   this.minRoasValue = this.roasRecommendedValue;
    //   this.minRoasChecked = true;
    // },
    onCancel() {
      this.setRoasValue = false;
      this.adjustBudget = false;
      this.minRoasChecked = false;
      this.$parent.key++;
    },
    transformTempBudget() {
      const oldVal =
        this.budgetTypeRadio === '0'
          ? this.initialBudgetSet
          : this.lifetimeValue;
      let delta = parseFloat(this.tempBudget);
      if (this.radioValue.radioValue === 'set_new_budget') {
        return delta;
      }
      if (this.radioValue?.dropdownValue === 'percentage') {
        delta = oldVal * (delta / 100);
      }
      if (this.radioValue?.radioValue === 'increase_budget') {
        return parseFloat((oldVal + delta).toFixed(2));
      } else {
        return parseFloat((oldVal - delta).toFixed(2));
      }
    },
    onSave() {
      this.getAdGroupDataForViewPayload();
      if (this.budgetTypeRadio === '0') {
        this.payloadDataForBudget = {
          new_budget_type: 'daily_budget',
          optimized_bidding_enabled: !!this.isOptimizedBiddingEnabled,
          new_daily_budget: this.transformTempBudget(),
          new_lifetime_budget: this.lifetimeValue,
          roas_constraint: this.minRoasChecked
            ? this.initialData?.roasValue !== this.minRoasValue
              ? this.minRoasValue
              : null
            : null,
          new_optimized_bidding_goal: this.isOptimizedBiddingEnabled
            ? this.bidType
            : null
        };
      }
      if (this.budgetTypeRadio === '1') {
        this.payloadDataForBudget = {
          new_budget_type: 'max_impressions',
          optimized_bidding_enabled: 'false',
          new_lifetime_budget: this.transformTempBudget()
        };
      }
      const reqPayload = cloneDeep(this.requestPayload);
      const reqPayloadOptimizedBiddingGoal = this.payloadDataForBudget
        ?.optimized_bidding_enabled
        ? this.bidType
        : null;
      const reqValues = {
        ':new_budget_type':
          this.initialData.budgetType !== this.budgetTypeRadio
            ? this.payloadDataForBudget?.new_budget_type
            : null,
        ':old_budget_type':
          this.initialData.budgetType !== this.budgetTypeRadio
            ? this.initialData?.budgetTypeView
            : null,
        ':optimized_bidding_enabled':
          this.payloadDataForBudget?.optimized_bidding_enabled,
        ':new_daily_budget':
          this.payloadDataForBudget?.new_daily_budget !==
            this.initialData?.dailyBudget && this.budgetTypeRadio === '0'
            ? +this.payloadDataForBudget?.new_daily_budget
            : null,
        ':old_daily_budget':
          this.payloadDataForBudget?.new_daily_budget !==
            this.initialData?.dailyBudget && this.budgetTypeRadio === '0'
            ? this.initialData?.dailyBudget
            : null,
        ':new_lifetime_budget':
          this.payloadDataForBudget?.new_lifetime_budget !==
          this.initialData?.lifetimeBudget
            ? +this.payloadDataForBudget?.new_lifetime_budget
            : null,
        ':old_lifetime_budget':
          this.payloadDataForBudget?.new_lifetime_budget !==
          this.initialData?.lifetimeBudget
            ? this.initialData?.lifetimeBudget
            : null,
        ':roas_constraint':
          this.initialData?.roasValue !==
          this.payloadDataForBudget?.roas_constraint
            ? this.payloadDataForBudget?.roas_constraint
            : null,
        ':old_roas_constraint':
          this.initialData?.roasValue !==
          this.payloadDataForBudget?.roas_constraint
            ? this.initialData?.roasValue
            : null,
        ':view_new_optimized_bidding_enabled':
          this.payloadDataForBudget?.optimized_bidding_enabled !==
          `${this.campaignInfo?.data?.optimized_bidding_enabled}`
            ? reqPayloadOptimizedBiddingGoal
            : null,
        ':view_old_optimized_bidding_enabled':
          this.payloadDataForBudget?.optimized_bidding_enabled !==
          `${this.campaignInfo?.data?.optimized_bidding_enabled}`
            ? this.initialData?.bid?.type
            : null,
        ':campaignId': this.entityId,
        ':pageUrl': window.location.href
      };

      for (const item in reqValues) {
        deepReplaceObjectValues(reqPayload, item, reqValues[item]);
      }

      reqPayload.actionPayload.new_budget_type =
        this.budgetTypeRadio === '1' ? 'max_impressions' : 'daily_budget';

      if (this.isOptimizedBiddingEnabled) {
        this.$store.dispatch(this.updateMetadata, {
          updateRequest: [reqPayload],
          entityId: this.entityId,
          entityType: reqPayload.actionPayload.campaignType,
          callAdgroupFetch: this.isAdgroup,
          snackbar: this.$snackbar,
          entityName: {
            singular: 'Budget Setting',
            plural: 'Budget Settings',
            noCount: true
          },
          routeData: this.$route
        });
      }
      if (
        this.budgetTypeRadio === '1' ||
        (this.budgetTypeRadio === '0' && this.bidType === 'manual')
        //  && this.adGroupDataForViewPayload?.length > 0
      ) {
        const payloadArr = [];
        for (const data of this.adGroupDataForViewPayload) {
          const requestPayloadForManual = cloneDeep(
            this.requestPayloadForManual
          );
          let reqPayloadData = {
            ':campaignId': this.entityId,
            ':adgroupId': data?.adgroupId,
            ':adgroupName': data?.adgroupName,
            ':newBid': data?.newBidValue,
            ':previousBid': data?.oldBidValue,
            ':pageUrl': window.location.href
          };
          for (const item in reqPayloadData) {
            deepReplaceObjectValues(
              requestPayloadForManual,
              item,
              reqPayloadData[item]
            );
          }
          payloadArr.push(requestPayloadForManual);
        }
        this.$store.dispatch(this.updateMetadata, {
          updateRequest: [...payloadArr, ...[reqPayload]],
          entityId: this.entityId,
          entityType: reqPayload.actionPayload.campaignType,
          callAdgroupFetch: this.isAdgroup,
          snackbar: this.$snackbar,
          entityName: {
            singular: 'Bid and Budget Setting',
            plural: 'Bid and Budget Settings',
            noCount: true
          },
          routeData: this.$route
        });
      }
      this.onCancel();
    },

    onAdjustBudget() {
      if (this.bidType === 'manual') {
        this.getAdGroupsData();
      }
      this.tempBudget =
        this.budgetTypeRadio === '0'
          ? this.initialBudgetSet
          : this.lifetimeValue;
      this.adjustBudget = true;
    },
    onUpdatingValues(item) {
      this.tempBudget = item?.inputValue;
      this.radioValue = item;
    }
  }
};
</script>
<style scoped lang="css">
.rec-card {
  display: flex;
  flex-direction: column;
  padding: 1.6rem;
  width: 26rem;
  justify-content: space-around;
  box-shadow: 0 0 4px 0 #caccce;
}
.rec-card .action-button {
  order: 5;
}
.rec-card .rb-button--hollow {
  color: #007cf6 !important;
  border: 1px solid #007cf6 !important;
  width: max-content;
  padding: 8px 16px;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  padding-right: 1.6rem;
  padding-left: 1.6rem;
}
.rec-card .rb-button--hollow:hover {
  color: #fff !important;
  border: 1px solid #007cf6 !important;
  background: #007cf6 !important;
}
.rec-card .count-class {
  margin-bottom: 1.6rem;
}
.rec-card .count-icon-class {
  color: #007cf6;
}
.rec-card .count-icon-text-class {
  font-size: 1.4rem;
  color: #000;
  font-weight: 600;
}
.rec-card .textClass {
  padding-left: 1rem;
}
.rec-card .rec-label-class {
  font-size: 1.3rem;
  margin-bottom: 0.8rem;
}
.rec-card .rec-description-class,
.rec-card .description {
  line-height: 1.2;
  font-size: 1.1rem;
  margin-bottom: 1.6rem;
}
.min-height-card {
  min-height: 630px;
}
.value-left {
  left: 8px;
  top: 12px;
}
.value-right {
  right: 8px;
  top: 12px;
}
.paginationFooter {
  border: 1px solid #4b5158 !important;
  background: none !important;
  padding-bottom: 14px;
  width: 100%;
  height: 54vh;
}
.table-right {
  height: 420px !important;
}
.appender {
  line-height: normal;
  height: 32px;
  border: 1px solid #e9eaeb;
  border-radius: 2px;
  border-left: 0;
}
.appender.currency-symbol {
  border: 1px solid #e9eaeb;
  border-right: 0;
}
.rb-input {
  font-size: 13px;
  color: #2b333b;
  outline: 0;
  line-height: 1;
  border: 1px solid #e9eaeb;
  border-radius: 2px;
}
.rb-input.disabled-color {
  color: #caccce;
}
.rb-input.currency-input {
  border-left: 0;
  padding-left: 4px;
  height: 32px;
}
</style>
